import frontend from "../assets/icons/frontend.png";
import backend from "../assets/icons/backend.png";
import ux from "../assets/icons/ux.png";
import git from "../assets/tech/git.png";
import docker from "../assets/tech/docker.png";
import postgresql from "../assets/tech/postgresql.png";
import coverhunt from "../assets/company/coverhunt.png";
import dcc from "../assets/company/dcc.png";
import kelhel from "../assets/company/kelhel.png";
import microverse from "../assets/company/microverse.png";
import sql from "../assets/tech/sql.png";
import aws from '../assets/tech/aws.png';
import gcloud from "../assets/tech/gcloud.png";
import grafana from "../assets/tech/grafana.png";
import jenkins from "../assets/tech/jenkins.png";
import ansible from "../assets/tech/ansible.png";
import nginx from "../assets/tech/nginx.png";
import azure from "../assets/tech/azure.png";
import devops from "../assets/certificates/devops.png";
import ci from "../assets/certificates/ci.jpg";
import devopsaws from "../assets/certificates/devops-aws.png";
import prototyping from "../assets/icons/prototyping.png";

export const navLinks = [
  {
    id: 'about',
    title: 'About',
  },
  {
    id: 'projects',
    title: 'Projects',
  },
  {
    id: 'contact',
    title: 'Contact',
  },
];

const services = [
  {
    title: "Automation",
    icon: frontend,
  },
  {
    title: "CI/CD",
    icon: backend,
  },
  {
    title: "Infrastructure as Code (IaC)",
    icon: ux,
  },
  {
    title: "Monitoring and Logging",
    icon: prototyping,
  },
];

const technologies = [
  {
    name: 'aws',
    icon: aws,
  },
  {
    name: 'ansible',
    icon: ansible,
  },
  {
    name: 'postgresql',
    icon: postgresql,
  },
  {
    name: 'git',
    icon: git,
  },
  {
    name: 'docker',
    icon: docker,
  },
  {
    name: 'azure',
    icon: azure,
  },
  {
    name: 'G-cloud',
    icon: gcloud,
  },
  {
    name: 'sql',
    icon: sql,
  },
  {
    name: 'grafana',
    icon: grafana,
  },
  {
    name: 'jenkins',
    icon: jenkins,
  },
  {
    name: 'nginx',
    icon: nginx,
  },
];

const experiences = [
  {
    title: 'DevOps Engineer',
    company_name: 'PayMe',
    icon: coverhunt,
    iconBg: '#333333',
    date: 'May 2022 - Present',
  },
  {
    title: 'Network Adminstrator',
    company_name: 'PayMe',
    icon: microverse,
    iconBg: '#333333',
    date: 'Jan 2019 - Apr 2022',
  },
  {
    title: 'Desktop Support Engineer',
    company_name: 'Netsoft',
    icon: kelhel,
    iconBg: '#333333',
    date: 'June 2018 - Dec 2019',
  },
  {
    title: 'MCA',
    company_name: 'Jain University, Banglore',
    icon: dcc,
    iconBg: '#333333',
    date: 'July 2022 - June 2024',
  },
];

const projects = [
  {
    id: "project-1",
    name: "Getting Started with Google Cloud ",
    description: "Successfully complete my G-Cloud Learning from LinkedIn",
    image: gcloud,
    repo: "#",
    demo: "https://www.linkedin.com/learning/certificates/fdb0dda0f7fdec791b7a46e23ca1a92bf91c05c40f918d7af44db1c1a44fd09b?u=92695330",
  },
  {
    id: "project-2",
    name: "Docker Essential Training: Installation and Configuration",
    description: "View my verified achievement from LinkedIn Learning.",
    image: docker,
    repo: "#",
    demo: "https://www.linkedin.com/learning/certificates/56c189fadc74a8ea8a1fc3f56bc3eb744563d0659c7f5386351935822865a84c?u=92695330",
  },
  {
    id: "project-3",
    name: "Jenkins Essential Training",
    description:
      "Jenkins Essential Training by  LinkedIn Learning Certificate of Completion",
    image: jenkins,
    repo: "#",
    demo: "https://www.linkedin.com/learning/certificates/9f8e1a584e564ad035c840c44940750f1b64e47128bf7768655708032c946516?u=92695330",
  },
  {
    id: "project-4",
    name: "Continuous Integration: Tools",
    description: "Automations and deploy on Server",
    image: ci,
    repo: "#",
    demo: "https://www.linkedin.com/learning/certificates/554e93d7c845c8dd8fa004c2d106c733928ac1b042424ef79e414e5f5376074c?u=92695330",
  },
  {
    id: "project-5",
    name: "DevOps With AWS",
    description:
      "LinkedIn Learning Certificate of Completion",
    image: devopsaws,
    repo: "#",
    demo: "https://www.linkedin.com/learning/certificates/a37f1ee988669969cdb0ae27fda9884276ce90e2fb7f11aa79111c351907641a?u=92695330",
  },
  {
    id: "project-6",
    name: "DevOps Completion by Simplilearn ",
    description: "Successfully complete my DevOps course",
    image: devops,
    repo: "#",
    demo: "https://drive.google.com/file/d/1mHwPvzRu3Iu3Fn03dYxCPODlfJBzxi-i/view?usp=drive_link",
  },
];

export { services, technologies, experiences, projects };
