import { motion } from "framer-motion";
// import { styles } from "../styles";
// import { services } from "../constants";
import { fadeIn } from "../utils/motion.js";
import { SectionWrapper } from "../hoc";
import { FaGithub, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
    const lkImageClick = () => {
        console.log("Image clicked!");
        window.open("https://www.linkedin.com/in/dheeraj-b4a6311aa/", "_blank"); 
      };
      const gitImageClick = () => {
        console.log("Image clicked!");
        window.open("https://github.com/dhira13", "_blank"); 
      };
    return (
      <div >
        <motion.p
          variants={fadeIn("", "", 0.1, 0.1)}
          className="text-taupe text-[18px] max-w-3xl leading-[20px]"
        >
          Thank you so much for visiting the website.
          I am always trying to meet new people and talk over a coffee . 
          Do not hesitate to say "Hi!".
        </motion.p>

        <div className="footer-icons flex align-center">
        <FaLinkedin onClick={lkImageClick} data-testid="lk-icon" />
        <FaGithub onClick={gitImageClick} data-testid="lk-icon" />
        </div>

      </div>
    );
  };

  export default SectionWrapper(Footer, "footer");
