// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// // import About from './About';
// // import { BrowserRouter, Routes, Route } from "react-router-dom";
// import reportWebVitals from './reportWebVitals';
// // import header from './Header';

// // const root = ReactDOM.createRoot(document.getElementById('root'));
// // root.render(
// //   <React.StrictMode>
// //     {/* <App />
// //     // <About /> */}
// //     <BrowserRouter>
// //       <Routes>
// //         <Route path="/" element={<App />}>
// //           {/* <Route path="about"index element={<About />} /> */}
// //           {/* <Route path="blogs" element={<Blogs />} />
// //           <Route path="contact" element={<Contact />} />
// //           <Route path="*" element={<NoPage />} /> */}
// //         </Route>
// //       </Routes>
// //     </BrowserRouter>
// //   </React.StrictMode>
// // );


// ReactDOM.render((
//   <Provider store={store}>
//   <App />
// ), document.getElementById('root'));
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.js'
import './index.css'

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
